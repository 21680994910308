import Header from "./rizeheader";

import Footer from "./footer";
import ValidatorComponent from "./validatorComponent";
export default function Validator() {
  return (
    <section className="rize-main select-wallet-bg" >
      {/*  ||--------------------------------------------------------------------------------|| */}
      {/*  || RIZE HEADER || */}
      {/*  ||--------------------------------------------------------------------------------|| */}
      <Header validator={false} />

      {/* ||--------------------------------------------------------------------------------|| */}
      {/* ||                                    SERVICES                                    || */}
      {/* ||--------------------------------------------------------------------------------|| */}
      <ValidatorComponent />

      {/* ||--------------------------------------------------------------------------------|| */}
      {/* ||                                     FOOTER                                     || */}
      {/* ||--------------------------------------------------------------------------------|| */}
      <Footer />
    </section>
  );
}
