const mainnet = true;
export const config = {
  ipfsStreamGateway:
    "https://silver-rapid-shrimp-610.mypinata.cloud?stream=true/ipfs/",
  ipfsGateway: "https://silver-rapid-shrimp-610.mypinata.cloud/ipfs/",
  socketUrl: "https://rizenfts.com",
  // baseUrl: "https://core-restaking-bot.onrender.com/api",
  baseUrl: "https://core-restaking-bot.onrender.com/api",
  API_URL: "https://rizenfts.com/",

  // RPC_URL: mainnet
  //   ? "https://rizenfts.com/cosmwasm/"
  //   : "https://race.cracers.com/coreum/", //'https://rpc-test.coreum.hexskrt.net/',
  RPC_URL: mainnet
    ? "https://full-node.mainnet-1.coreum.dev:26657/"
    : "https://full-node.testnet-1.coreum.dev:26657/", //'https://coreum-rpc.publicnode.com:443/',

  // REST_URL: mainnet
  //   ? "https://rizenfts.com/rest"
  //   : "https://race.cracers.com/coreum-rest/",
  
  // TEST_WALLET: "",
  // TEST_STAKINGAMOUNT: 10,  

  REST_URL: mainnet
    ? "https://full-node.mainnet-1.coreum.dev:1317/"
    : "https://full-node.testnet-1.coreum.dev:1317/",

  DATA_LAYER: mainnet ? "" : "",
  FAUCET_URL: "",
  CHAIN_ID: mainnet ? "coreum-mainnet-1" : "coreum-testnet-1",
  CHAIN_NAME: mainnet ? "Coreum Mainnet 1" : "Coreum Testnet 1",
  COIN_DENOM: mainnet ? "CORE" : "TESTCORE",
  COIN_MINIMAL_DENOM: mainnet ? "ucore" : "utestcore",
  COIN_TYPE: mainnet ? 990 : 990,
  COIN_DECIMALS: 6,
  COIN_GECKOID: mainnet ? "coreum" : "coreum",
  HD_PATH: "44/990/0/0/0",
  PREFIX: mainnet ? "core" : "testcore",
  AVG_GAS_STEP: 0.005,
  MAXIMUM_UPLOAD: 100,

  //   MARKETPLACE: mainnet
  //     ? "devcore1xryveqc63z0l2q6h4a3h0c02ftscwdlm0f2elhngczgau9lefpmqzn69lq"
  //     : "testcore1f6jlx7d9y408tlzue7r2qcf79plp549n30yzqjajjud8vm7m4vds64070x",
  //   CW721_CONTRACT: "devcore...",
  //   CW721_OWNER: "devcore...",
  //   CW20_CONTRACT: mainnet
  //     ? "devcore143gjvxqvea69v0hqp2p6wtz386hhgdn06uw7xntyhleahaunjkqs0jx6pc"
  //     : "testcore1qg5ega6dykkxc307y25pecuufrjkxkaggkkxh7nad0vhyhtuhw3sqwjxmq",
  //   RIZE_STAKING_CONTRACT: mainnet ? "devcore143gjvxqvea69v0hqp2p6wtz386hhgdn06uw7xntyhleahaunjkqs0jx6pc" : "testcore1fvyph5d283r3rf75a6n4keu9agdtu0agqf7qfp9umvtwxm35xg9s3d79z6",

  RIZE_VALIDATOR_ADDRESS: mainnet
    ? "corevaloper1eg7rdhf8mz8dhkxq6r2dtfkxkyds3330jggvkv"
    : "testcorevaloper107pd89u5x20wxucx9yjk5wysv5rttpvu8vnvsf",
  RIZE_OPERATOR_ADDRESS: mainnet
    ? "core1xpy3yyxwpe5k60as7ckym84v6dw3ssat0tqyl9"
    : "testcore107pd89u5x20wxucx9yjk5wysv5rttpvu8x9uaq",
  RIZE_ESCROW_ADDRESS: mainnet
    ? "core1vjqlhj9ec4yuf93xmggfc4n9nl522y7mteh483"
    : "",
  DONOR_STAKE_ADDRESS: mainnet
    ? "core10qx4z4405dduf55r58vlrtuxqxf28gppp3ny4w"
    : "",
  DONOR_DISTRIBUTION_ADDRESS: mainnet
    ? "core16chk8m4q6qvdf8u8p7u8k98rwyh0s8dlcud58j"
    : "",
  EARNWISE_STAKE_ADDRESS: mainnet
    ? "core1g8zrusy408ns3gk2jphwpuulvj8nv8rtyejk2j"
    : "",
  EARNWISE_DISTRIBUTION_ADDRESS: mainnet
    ? "core12vewe3fz4gypgg9mzfnsfldtm9tc47cdx5e8k9"
    : "",
  DRIP_STAKE_ADDRESS: mainnet
    ? "core1ahaxc0d6vj94zus4jq4wlxey9ukmph9a94eh46"
    : "",
  DRIP_PAYOUT_ADDRESS: mainnet
    ? "core14e4z2mrlpkeylwtxhj5yc3uhfj37jsmzphrqlm"
    : "",

  COLLECTION_CODE_ID: mainnet ? 42 : 6,
  CW721_CODE_ID: mainnet ? 41 : 4,

  MSG_GRANT: "/cosmos.authz.v1beta1.MsgGrant",
  MSG_REVOKE: "/cosmos.authz.v1beta1.MsgRevoke",
  MSG_EXEC: "/cosmos.authz.v1beta1.MsgExec",
  MSG_REWARD: "/cosmos.distribution.v1beta1.MsgWithdrawDelegatorReward",
  MSG_DELEGATE: "/cosmos.staking.v1beta1.MsgDelegate",
  MSG_GEN_AUTHZ: "/cosmos.authz.v1beta1.GenericAuthorization",
  MSG_STAKING_AUTHZ: "/cosmos.staking.v1beta1.StakeAuthorization",
  MSG_SEND: "/cosmos.bank.v1beta1.MsgSend",
  MSG_UNDELEGATE: "/cosmos.staking.v1beta1.MsgUndelegate",

};

export const PINATA_JWT =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiIxYTY2NTdhMi1jYmYzLTQzOGEtODI4Yy02ZTg1Y2U3MzBiNmUiLCJlbWFpbCI6InN1cHBvcnRAcml6ZTJkYXkuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsInBpbl9wb2xpY3kiOnsicmVnaW9ucyI6W3siaWQiOiJGUkExIiwiZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjF9LHsiaWQiOiJOWUMxIiwiZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjF9XSwidmVyc2lvbiI6MX0sIm1mYV9lbmFibGVkIjpmYWxzZSwic3RhdHVzIjoiQUNUSVZFIn0sImF1dGhlbnRpY2F0aW9uVHlwZSI6InNjb3BlZEtleSIsInNjb3BlZEtleUtleSI6IjFkZWUxYTYzMDAwMzA3NTUyYjEyIiwic2NvcGVkS2V5U2VjcmV0IjoiYzMwNjg5YjViM2U1MmM1MTFlYTc5ZGRkODdhNmExODJlNzUyMWY1ZTRmMTA5ZjU5OTMxMTg0Mzk3OGY0YmUwNiIsImlhdCI6MTY3ODI3NTIxN30.N4WqxO0FsBz_m-zsAlbVzN2ZoXktiTFisFHXtgyDw38";

export const chainConfig = {
  chainId: config.CHAIN_ID,
  chainName: config.CHAIN_NAME,
  rpc: config.RPC_URL,
  rest: config.REST_URL,
  stakeCurrency: {
    coinDenom: config.COIN_DENOM,
    coinMinimalDenom: config.COIN_MINIMAL_DENOM,
    coinDecimals: config.COIN_DECIMALS,
    coinGeckoId: config.COIN_GECKOID,
  },
  bip44: {
    coinType: config.COIN_TYPE,
  },
  bech32Config: {
    bech32PrefixAccAddr: `${config.PREFIX}`,
    bech32PrefixAccPub: `${config.PREFIX}pub`,
    bech32PrefixValAddr: `${config.PREFIX}valoper`,
    bech32PrefixValPub: `${config.PREFIX}valoperpub`,
    bech32PrefixConsAddr: `${config.PREFIX}valcons`,
    bech32PrefixConsPub: `${config.PREFIX}valconspub`,
  },
  currencies: [
    {
      coinDenom: config.COIN_DENOM,
      coinMinimalDenom: config.COIN_MINIMAL_DENOM,
      coinDecimals: config.COIN_DECIMALS,
      coinGeckoId: config.COIN_GECKOID,
    },
  ],
  feeCurrencies: [
    {
      coinDenom: config.COIN_DENOM,
      coinMinimalDenom: config.COIN_MINIMAL_DENOM,
      coinDecimals: config.COIN_DECIMALS,
      coinGeckoId: config.COIN_GECKOID,
      gasPriceStep: {
        low: 0.0625,
        average: 1,
        high: 62.5,
      },
      features: ["cosmwasm"],
    },
  ],
  gasPriceStep: {
    low: 0.0625,
    average: 1,
    high: 62.5,
  },
  features: ["cosmwasm"],
  coinType: config.COIN_TYPE,
  beta: true,
};

export const FILE_TYPE = {
  ALL: 0,
  IMAGE: 1,
  AUDIO: 2,
  VIDEO: 3,
  THREED: 4,
};

export const CATEGORIES = [
  { value: 1, text: "Arts" },
  { value: 2, text: "Games" },
  { value: 3, text: "Sports" },
  { value: 4, text: "Audio" },
  { value: 5, text: "Video" },
  { value: 6, text: "3D NFTs" },
  { value: 7, text: "Photography" },
];

export const PROPERTY_TYPES = [
  { value: 1, text: "string" },
  { value: 2, text: "boolean" },
  { value: 3, text: "number" },
  { value: 4, text: "textarea" },
];

export const PROFILE_TABS = {
  COLLECTIBLES: 0,
  CREATED: 1,
  LIKED: 2,
  FOLLOWING: 3,
  FOLLOWERS: 4,
  COLLECTIONS: 5,
};
