import { FC, useState, useEffect, useRef, MouseEvent, Fragment } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useSigningClient } from "../app/cosmwasm";
import { config } from "../app/config";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
// import Slider from 'react-rangeslider';
import Slider from "@mui/material/Slider";
import * as React from "react";

import serviceImg from "../assets/images/img/attachments/services.png";
import utilityImg from "../assets/images/img/attachments/Utility.png";
import Rize_Validator_Node from "../assets/images/img/attachments/Rize_Validator_Node.png";
import Rize_Learn_Stake from "../assets/images/img/attachments/Rize_Learn_Stake.png";
import Info from "../assets/images/img/info.svg";
import Copy from "../assets/images/img/copy.svg";
import bridgeImg from "../assets/images/img/Bridge.jpg";
import {
  copyToClipboard,
  formatNumberWithCommas,
  getMidAddress,
  getShortAddress,
} from "../app/utils";
import {
  Box,
  Fade,
  Input,
  InputAdornment,
  Modal,
  Typography,
} from "@mui/material";

const remainAmount = 0.1;

function Service() {
  const {
    client,
    signingClient,
    loadClient,
    walletIndex,
    walletAddress,
    balances,
    restakingStatus,
    connectWallet1,
    disconnect,
    executeStake,
    executeReward,
    enableRestake,
    disableRestake,
    executeUnstake,
  } = useSigningClient();
  // console.log("[body] >>>>>>>", walletAddress);
  // console.log("[body] current wallet index ========================================> ", walletIndex);
  // console.log("[body] balances ========================================> ", balances);
  // console.log("[body] restaking status ========================================> ", restakingStatus);

  const availableAmount =
    // balances?.ucore > remainAmount ? balances?.ucore - remainAmount : 0;
    balances?.user_balance > remainAmount ? (balances?.user_balance - remainAmount)/10**6 : 0;


  const [loadingView, setLoadingView] = useState(false);
  const [stakingAmount, setStakingAmount] = useState("");
  const [unstakingAmount, setUnstakingAmount] = useState("");
  const [restakePercentage, setRestakePercentage] = useState(10); //default to 10
  const [isMobile, setIsMobile] = useState(false);
  const [autoDesc, setAutoDesc] = useState(false);
  const [claimDesc, setClaimDesc] = useState(false);
  const handleClose = () => setAutoDesc(false);
  const handleShow = () => setAutoDesc(true);
  const handleClaimClose = () => setClaimDesc(false);
  const handleClaimShow = () => setClaimDesc(true);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    }
  }, []);
  useEffect(() => {
    setRestakePercentage(restakingStatus?.percentage > 90? 90 : restakingStatus?.percentage < 10 ? 10 : restakingStatus?.percentage);
  }, [restakingStatus?.percentage]);

  const authenticate = async (index = 0) => {
    if (walletAddress.length === 0) {
      await connectWallet1(index);
    } else {
      disconnect();
      if (walletIndex != index) {
        await connectWallet1(index);
      }
    }
  };

  // console.log("=============", balances);

  const walletButtonClass = (index) => {
    return walletAddress && walletIndex == index
      ? "rize-btn-green-outline-selected text-black"
      : "rize-btn-green-outline text-white";
  };

  const handleStake = async () => {
    if (!signingClient || walletAddress.length === 0) {
      toast.warn("Wallet not connected");
      return;
    }

    if (!stakingAmount) {
      toast.warn("Staking amount is not set");
      return;
    }

    setLoadingView(true);
    await executeStake(stakingAmount);
    setLoadingView(false);
  };

  const handleUnstake = async () => {
    if (!signingClient || walletAddress.length === 0) {
      toast.warn("Wallet not connected");
      return;
    }

    if (!balances?.delegated) {
      toast.warn("No staked");
      return;
    }

    if (!unstakingAmount) {
      toast.warn("Unbond amount is not set");
      return;
    }

    setLoadingView(true);
    await executeUnstake(unstakingAmount);
    setLoadingView(false);
  };

  const handleEnableRestake = async () => {
    if (!signingClient || walletAddress.length === 0) {
      toast.warn("Wallet not connected");
      return;
    }

    if (restakingStatus?.isCreated && restakingStatus?.enable) {
      toast.warn(
        "Already done. If you want to update restaking, please cancel first."
      );
      return;
    }

    if (!restakePercentage) {
      toast.warn("Staking amount is not set");
      return;
    }

    setLoadingView(true);
    await enableRestake(restakePercentage);
    setLoadingView(false);
  };

  const handleCancelRestake = async () => {
    if (!signingClient || walletAddress.length === 0) {
      toast.warn("Wallet not connected");
      return;
    }
    // console.log("#####################################")
    if (!restakingStatus?.isCreated || !restakingStatus?.enable) {
      toast.warn("Already cancelled!");
      return;
    }

    setLoadingView(true);
    await disableRestake();
    setLoadingView(false);
  };

  const handleReward = async () => {
    if (!signingClient || walletAddress.length === 0) {
      toast.warn("Wallet not connected");
      return;
    }

    if (restakingStatus?.isCreated && restakingStatus?.enable) {
      toast.warn("Auto Restaking enabled!");
      return;
    }

    if (balances?.reward <= 0) {
      toast.warn("No claimable reward!");
      return;
    }

    setLoadingView(true);
    await executeReward();
    setLoadingView(false);
  };

  const handleCopy = (text) => {
    copyToClipboard(text);
    toast.success("Copied!");
  };

  const image1 = "/public/Cosmostation.png";
  const image2 = "/public/Ledger.png";
  const image3 = "/public/Leap-Wallet.png";
  const image4 = "/public/Keplr.png";

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    fontSize: "20px",
    borderRadius: "6px",
    border: "1px solid #33ff00",
    backgroundColor: "#333",
    color: "white",
    textAlign: "center",
    padding: "20px",
    transition: " opacity 0.2s ease -in -out, visibility 0.2s ease -in -out",
  };

  // console.log("restakingStatus:::::::", restakingStatus);

  return (
    <section className="mt-1 pt-3 main-container" data-aos="fade-up">
      <div className="rize-suboverlay-container px-5 mb-3">
        {/* RIZE WALLET BODY */}
        <div className="row mt-3 h-100 ">
          <div className=" mb-5">
            <div className="h-100">
              {/* RIZE WALLET TITLE CONTAINER */}
              <div className="d-flex flex-column justify-content-center align-items-center mb-5">
                <div className="selectWallet staking-title">COREUM STAKING</div>
              </div>

              {/* Wallet Options */}
              <div className="rize-wallet-title-container">
                <button
                  className={`btn ${walletButtonClass(
                    1
                  )} v-center  walletOptions`}
                  onClick={() => authenticate(1)}
                >
                  <img
                    src={require("../assets/images/logo/Cosmostation.png")}
                    alt="image not found"
                    width={25}
                  />
                  Cosmostation
                </button>

                <button
                  className={`btn ${walletButtonClass(
                    2
                  )} v-center  walletOptions`}
                  onClick={() => authenticate(2)}
                >
                  <img
                    src={require("../assets/images/logo/Ledger.png")}
                    alt="image not found"
                    width={25}
                  />
                  Ledger
                </button>

                <button
                  className={`btn ${walletButtonClass(
                    3
                  )} v-center  walletOptions`}
                  onClick={() => authenticate(3)}
                >
                  <img
                    src={require("../assets/images/logo/Leap-Wallet.png")}
                    alt="image not found"
                    width={40}
                  />
                  Leap
                </button>

                <button
                  className={`btn ${walletButtonClass(
                    0
                  )} v-center  walletOptions`}
                  onClick={() => authenticate(0)}
                >
                  <img
                    src={require("../assets/images/logo/Keplr.png")}
                    alt="image not found"
                    width={25}
                  />
                  Keplr
                </button>

                <button className="btn rize-btn-green-outline text-white v-center  walletOptions">
                  Coming Soon
                </button>
              </div>

             
            </div>
          </div>

          <div className="mt-3 mt-xl-0 d-flex flex-column justify-content-between">
            <div className="mb-3">
              {/* Rize Validator */}
              <div className="pl-sm-0">
                <div className="cardContainer  mb-3">
                  <div className="pageCard">
                    <div className="d-flex">
                      <span className="wallet-body-title-1 pr-3 pl-3">
                        Staked Tokens
                      </span>
                    </div>
                    <div className=" wallet-body-sub-row mt-3">
                      {formatNumberWithCommas(balances?.delegated?.toFixed(2))}{" "}
                      CORE
                    </div>
                    <div className="d-flex justify-content-center wallet-body-mytoken pr-3 text-white hidden">
                      <b className="bold-green">Lock more Tokens</b> &nbsp;to
                      increase your yield
                    </div>
                  </div>
                  <div className="pageCard">
                    <div className="d-flex">
                      <span className="wallet-body-title-1 pr-3 pl-3">
                        Available Tokens
                      </span>
                    </div>
                    <div className=" wallet-body-sub-row mt-3">
                      {/* {formatNumberWithCommas(balances?.ucore?.toFixed(2))} CORE */}
                       <span className="flash-text" >{balances.user_balance/(10**6) || 0} &nbsp;</span> CORE
                    </div>
                  </div>
                </div>
              </div>

              {/* Stake */}
              <div className="pl-sm-0 mt-4">
                <div className="d-flex">
                  <span className="wallet-body-title-1 pr-3 mb-3">Stake</span>
                </div>
                <div className="row wallet-body-sub-row2 m-0">
                  <div className="col-12 p-0">
                    <div className="">
                      <div className="p-relative">
                        <input
                          type="number"
                          placeholder=".1 CORE remains for future transactions"
                          className="rize-input mb-2"
                          value={stakingAmount}
                          onChange={(event) => {
                            if (event.target.value > availableAmount) return;
                            setStakingAmount(event.target.value.toString());
                          }}
                          max={availableAmount}
                        />
                        {/* <button className="btn rize-btn-green rize-btn-sm-cus " onClick={() => setStakingAmount(availableAmount.toFixed(6))}> */}
                        <span
                          className=" rize-text-green rize-btn-sm-cus"
                          onClick={() =>
                            setStakingAmount(availableAmount.toFixed(6))
                          }
                        >
                          MAX
                        </span>
                        {/* </button> */}
                      </div>
                      <button
                        style={{ minWidth: 120, width: "100%" }}
                        className="btn rize-btn-green v-center h-center mb-1"
                        onClick={handleStake}
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* Unbond */}
              <div className="pl-sm-0 mt-4">
                <div className="d-flex mb-2">
                  <span className="wallet-body-title-1 pr-3">Unbond</span>
                </div>
                <div className="row wallet-body-sub-row2 m-0">
                  <div className="col-12 p-0">
                    <div className="">
                      <div className="p-relative mb-2">
                        <input
                          type="number"
                          className="rize-input"
                          value={unstakingAmount}
                          onChange={(event) => {
                            if (event.target.value > balances?.delegated)
                              return;
                            setUnstakingAmount(event.target.value.toString());
                          }}
                          max={balances?.delegated}
                        />
                        {/* <button className="btn rize-btn-green rize-btn-sm-cus " onClick={() => setUnstakingAmount(balances?.delegated?.toFixed(6))}>
                          <span className="d-block">Max</span>
                        </button> */}
                        <span
                          className=" rize-text-green rize-btn-sm-cus"
                          onClick={() =>
                            setUnstakingAmount(balances?.delegated?.toFixed(6))
                          }
                        >
                          MAX
                        </span>
                      </div>
                      <button
                        style={
                          balances?.delegated
                            ? { minWidth: 120, width: "100%" }
                            : {
                                minWidth: 120,
                                width: "100%",
                                borderColor: "#34f901",
                                color: "#7f847f",
                              }
                        }
                        className="btn rize-btn-green-outline v-center h-center"
                        onClick={handleUnstake}
                        disabled={!balances?.delegated}
                      >
                        Unbond
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className=" d-flex flex-column justify-content-between">
              {/* Restake */}
              <div className="pl-sm-0 mt-4">
                <div className="d-flex mb-2">
                  <span className="wallet-body-title-1 pr-3">
                    Auto Compound{" "}
                  </span>
                  <div className="tooltip-container">
                    <img
                      className="wallet-body-title-pr-3-img with-label button-tooltip "
                      src={Info}
                      style={{ cursor: "pointer" }}
                      onClick={handleShow}
                    />
                    {/* <div className="button-tooltip-text">Available only with Rize Earnwise!<br />Approve this feature to automatically compound different amounts of your rewards. This automation technique allows you to maximize your compounding rewards and have additional tokens to use as desired.</div> */}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-8">
                    <div
                      className="wallet-body-slider m-0 v-center h-100"
                      style={{ padding: "2px 14px" }}
                    >
                      <div className="wallet-body-stoke-text pr-2"> <nobr>Enter 10% - 90%  &nbsp; </nobr> </div>{" "}
                      {/* <input
                        type="range"
                        class="form-range rize-range-slider"
                        id="customRange1"
                        min={10}
                        max={90}
                        step={1}
                        value={restakePercentage}
                        disabled={restakingStatus?.isCreated && restakingStatus?.enable}
                        onChange={(event) => {
                          setRestakePercentage(event.target.value);
                        }}
                      /> */}
                      <Slider
                        id="customRange1"
                        className="rize-range-slider"
                        valueLabelDisplay="off"
                        min={10}
                        max={90}
                        step={1}
                        value={restakePercentage}
                        disabled={
                          restakingStatus?.isCreated && restakingStatus?.enable
                        }
                        onChange={(event, value) => {
                          setRestakePercentage(value > 90? 90: value < 10 ? 10 : value);
                        }}
                        style={{ color: "#30ed01" }}
                        aria-labelledby="input-slider"
                      />
                      {/* <div className="wallet-body-stoke-text pl-3">MAX</div> */}
                      <Input
                        value={restakePercentage}
                        className="ml-2"
                        size="small"
                        onChange={(event) => {
                          setRestakePercentage(event.target.value > 90?  90 : event.target.value < 10 ? 10: event.target.value);
                        }}
                        inputProps={{
                          step: 1,
                          min: 10,
                          max: 99,
                          type: "number",
                          "aria-labelledby": "input-slider",
                          className: "restake-input",
                        }}
                        disabled={
                          restakingStatus?.isCreated && restakingStatus?.enable
                        }
                        disableUnderline={true}
                      />
                    </div>
                  </div>
                  <div className="col-sm-4 mt-sm-0 mt-3 pl-md-0 pl-sm-1">
                    <div
                      className={
                        restakingStatus?.isCreated && restakingStatus?.enable
                          ? `wallet-body-sub-row m-0 p-0 p-2 v-center justify-content-center p-relative h-100`
                          : `wallet-body-sub-row12 m-0 v-center justify-content-center p-relative h-100`
                      }
                      onClick={handleEnableRestake}
                    >
                      {/* <img className="icon-info" src={Info} title="Hi we hope you enjoy this first of its kind feature!! 
                        Variable restake!! 
You set how much you want restaked, we pay the 
fees, you earn compounded rewards while also 
having a bag ready to sell while the time is right!" /> */}
                      <div className="d-flex align-items-center justify-content-center flex-column">
                        <div
                          id="stktext"
                          className="wallet-body-stoke-text1 pr-3"
                          style={
                            restakingStatus?.isCreated &&
                            restakingStatus?.enable
                              ? { color: "#7f847f" }
                              : { color: "#30ed01" }
                          }
                        >
                          <span>Enable</span>
                          <span
                            style={
                              restakingStatus?.isCreated &&
                              restakingStatus?.enable
                                ? { color: "#7f847f" }
                                : { color: "#fff" }
                            }
                          >
                            {""}
                            Restake
                          </span>
                        </div>{" "}
                        {/* <div className="wallet-body-stoke-text2 pl-3 mt-1">
                          Rize Pays Fees
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Cancel Restake */}
                <div className="pl-sm-0 d-flex flex-column justify-content-between">
                  <button
                    className="btn rize-btn-red-outline m-0 mt-1"
                    disabled={
                      !restakingStatus?.isCreated || !restakingStatus?.enable
                    }
                    onClick={handleCancelRestake}
                  >
                    Cancel Restake
                  </button>
                </div>
              </div>

              {/*Claim reward button*/}
              <div className="pl-sm-0 mt-4 d-flex flex-column justify-content-between">
                <div className="d-flex mb-3">
                  <span className="wallet-body-title-1 pr-3">
                    Claim Rewards{" "}
                  </span>
                  <div className="tooltip-container">
                    <img
                      className="wallet-body-title-pr-3-img with-label button-tooltip"
                      src={Info}
                      title="Click below to claim your earned rewards from staking with Rize Earnwise"
                      style={{ cursor: "pointer" }}
                      onClick={handleClaimShow}
                    />
                    {/* <div className="button-tooltip-text">Click below to claim your earned rewards from staking with Rize Earnwise</div> */}
                  </div>
                </div>
                <div
                  className={
                    restakingStatus?.isCreated && restakingStatus?.enable
                      ? `wallet-body-sub-row3 m-0 v-center restake-btn p-relative claim-rwd`
                      : `wallet-body-sub-row1x m-0 v-center restake-btn p-relative claim-rwd`
                  }
                  onClick={handleReward}
                >
                  {/* <div className="clmrwddiv"> */}
                  <div
                    className="wallet-body-title pr-3 clmrwd"
                    style={{ margin: "auto" }}
                  >
                    <span className="claim-option-1-core-text v-center h-center">
                      {balances.reward} CORE
                    </span>
                  </div>
                  {/* </div> */}
                  {/* <img class="icon-info" src={Info} /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingView}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={autoDesc}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={autoDesc}>
          <Box sx={style}>
            <div className="button-tooltip-text">
              Available only with Rize Earnwise!
              <br />
              Approve this feature to automatically compound different amounts
              of your rewards. This automation technique allows you to maximize
              your compounding rewards and have additional tokens to use as
              desired.
            </div>
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={claimDesc}
        onClose={handleClaimClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={claimDesc}>
          <Box sx={style}>
            <div className="button-tooltip-text">
              Click below to claim your earned rewards from staking with Rize
              Earnwise
            </div>
          </Box>
        </Fade>
      </Modal>
    </section>
  );
}

export default Service;
