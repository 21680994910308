// Import the functions you need from the SDKs you need
import { getDatabase } from "firebase/database";
import * as firebase from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDZn9CDpa6dJGjP9h1QFXEQNTpLDgIL4zQ",
    authDomain: "rize-staking.firebaseapp.com",
    databaseURL: "https://rize-staking-default-rtdb.firebaseio.com/",
    projectId: "rize-staking",
    storageBucket: "rize-staking.appspot.com",
    messagingSenderId: "1035159606025",
    appId: "1:1035159606025:web:9bb2e3a07fabd9aef239d8",
    measurementId: "G-769H0FDPS2"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const database = getDatabase(app);

export default database;