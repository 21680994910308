import logo from "./logo.svg";
import Homepage from "./screens/homepage";
function App() {
  return (
    <div className="App">
      <Homepage />
    </div>
  );
}

export default App;
