import { useState, useEffect, createContext, useContext } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import {
  CosmWasmClient,
  SigningCosmWasmClient,
} from "@cosmjs/cosmwasm-stargate";
import { SigningStargateClient } from "@cosmjs/stargate";
import { fromBase64, toBase64 } from "@cosmjs/encoding";
import { coins, encodePubkey, makeSignDoc, Registry } from "@cosmjs/proto-signing";
import { sha256, stringToPath } from "@cosmjs/crypto";
import { GenericAuthorization } from "cosmjs-types/cosmos/authz/v1beta1/authz";
import {
  StakeAuthorization,
  AuthorizationType,
} from "cosmjs-types/cosmos/staking/v1beta1/authz";
import { Timestamp } from "cosmjs-types/google/protobuf/timestamp";
import { getOfflineSigner } from "@cosmostation/cosmos-client";
import { LedgerSigner } from "@cosmjs/ledger-amino";
import TransportWebUSB from "@ledgerhq/hw-transport-webusb";
import TransportWebBLE from "@ledgerhq/hw-transport-web-ble";

import { config, chainConfig } from "./config.js";
import {
  convertDenomToMicroDenom,
  convertMicroDenomToDenom,
  isEmpty,
  setExpiry,
} from "./utils";
import database from "./firebase.js";
import {
  ref,
  child,
  push,
  update,
  remove,
  query,
  equalTo,
  onValue,
  orderByKey,
  orderByValue,
  orderByChild,
} from "firebase/database";

async function getKeplr() {
  if (window.keplr) {
    return window.keplr;
  }

  if (document.readyState === "complete") {
    return window.keplr;
  }

  return new Promise((resolve) => {
    const documentStateChange = (event) => {
      if (event.target && event.target.readyState === "complete") {
        resolve(window.keplr);
        document.removeEventListener("readystatechange", documentStateChange);
      }
    };

    document.addEventListener("readystatechange", documentStateChange);
  });
}

async function getCosmostation() {
  if (window.cosmostation) {
    return window.cosmostation;
  }

  if (document.readyState === "complete") {
    return window.cosmostation;
  }

  return new Promise((resolve) => {
    const documentStateChange = (event) => {
      if (event.target && event.target.readyState === "complete") {
        resolve(window.cosmostation);
        document.removeEventListener("readystatechange", documentStateChange);
      }
    };

    document.addEventListener("readystatechange", documentStateChange);
  });
}

async function getLeap() {
  if (window.leap) {
    return window.leap;
  }

  if (document.readyState === "complete") {
    return window.leap;
  }

  return new Promise((resolve) => {
    const documentStateChange = (event) => {
      if (event.target && event.target.readyState === "complete") {
        resolve(window.leap);
        document.removeEventListener("readystatechange", documentStateChange);
      }
    };

    document.addEventListener("readystatechange", documentStateChange);
  });
}

const defaultFee = {
  amount: [
    {
      denom: config.COIN_MINIMAL_DENOM,
      amount: "100000",
    },
  ],
  gas: "250000",
};

// const defaultFee = {
//     amount: [],
//     gas: "100000",
// };

const CosmwasmContext = createContext({});
export const useSigningClient = () => useContext(CosmwasmContext);

export const SigningCosmWasmProvider = ({ children }) => {
  const [client, setClient] = useState(null);
  const [signingClient, setSigningClient] = useState(null);
  const [walletIndex, setWalletIndex] = useState(0);
  const [walletAddress, setWalletAddress] = useState("");
  const [balances, setBalances] = useState({});
  const [restakingStatus, setRestakingStatus] = useState({});

  useEffect(() => {
    fetchBalance();
    fetchRestaking();
  }, [walletAddress]);

  const loadClient = async (rpc = "") => {
    try {
      const temp = await CosmWasmClient.connect(config.RPC_URL);
      setClient(temp);
    } catch (error) {
      throw error;
    }
  };

  const connectWallet1 = async (index = 0, new_config = null) => {
    let walletConfig = chainConfig;
    if (!isEmpty(new_config)) {
      walletConfig = new_config;
    }

    // console.log(index, ",,,,,,,,,,,,,,,,,,,");

    if (index == 0) {
      // console.log("----------------- keplr ------------------------");
      // console.log('*******************', window.keplr);
      const keplr = await getKeplr();

      if (!window.getOfflineSigner || !window.keplr || !keplr) {
        alert("Please install keplr to continue.");
        window.open("https://www.keplr.app/", "_blank");
        return;
      } else {
        if (window.keplr.experimentalSuggestChain) {
          try {
            await window.keplr.experimentalSuggestChain(walletConfig);
          } catch (error) {
            console.log(error);
            toast.error("Failed to suggest the chain");
            return;
          }
        } else {
          toast.warn("Please use the recent version of keplr extension");
          return;
        }
      }

      try {
        await keplr.enable(walletConfig.chainId);
        window.addEventListener("keplr_keystorechange", async () =>
          connectWallet1(0)
        );
      } catch (err) {
        console.log(err);
        return;
      }

      try {
        const offlineSigner = await window.keplr.getOfflineSigner(
          walletConfig.chainId
        );

        const tempClient = await SigningCosmWasmClient.connectWithSigner(
          walletConfig.rpc,
          offlineSigner
        );
        setSigningClient(tempClient);
        // console.log(")))))))))))))", tempClient);

        const accounts = await offlineSigner.getAccounts();
        const address = accounts[0].address;
        setWalletAddress(address);
        setWalletIndex(0);
        localStorage.setItem("address", address);
      } catch (err) {
        console.log("Connect Keplr Wallet: ", err);
        return;
      }
    } else if (index == 1) {
      // console.log("----------------- cosmostation ------------------------");

      const cosmostation = await getCosmostation();
      // console.log("**************", cosmostation);

      if (!window.cosmostation || !cosmostation) {
        alert("Please install cosmostation to continue.");
        window.open("https://www.cosmostation.io/wallet", "_blank");
        return;
      }
      // else {
      //     if (cosmostation.providers.keplr.experimentalSuggestChain) {
      //         console.log("##################################");
      //         try {
      //             await cosmostation.providers.keplr.experimentalSuggestChain(walletConfig);
      //         } catch (error) {
      //             console.log(error);
      //             toast.error("Failed to suggest the chain");
      //             return;
      //         }
      //     } else {
      //         toast.warn("Please use the recent version of cosmostation extension");
      //         return;
      //     }
      // }

      // try {
      //     await cosmostation.providers.keplr.enable(walletConfig.chainId);
      // } catch (err) {
      //     console.log(err);
      //     return;
      // }

      try {
        await cosmostation.cosmos.request({
          method: "cos_requestAccount",
          params: { chainName: walletConfig.chainId },
        });
        cosmostation.cosmos.on("accountChanged", async () => connectWallet1(1));
      } catch (err) {
        console.log(err);
        return;
      }

      try {
        const offlineSigner = await getOfflineSigner(walletConfig.chainId);
        // const offlineSigner = await window.cosmostation.providers.keplr.getOfflineSigner(
        //     walletConfig.chainId
        // );
        const tempClient = await SigningStargateClient.connectWithSigner(
          walletConfig.rpc,
          offlineSigner
        );
        // console.log(")))))))))))))", tempClient);
        setSigningClient(tempClient);

        const accounts = await offlineSigner.getAccounts();
        console.log("accounts", accounts)
        const address = accounts[0].address;
        
        setWalletAddress(address);
        setWalletIndex(1);
        localStorage.setItem("address", address);
      } catch (err) {
        console.log("Connect Cosmostation Wallet: ", err);
        return;
      }
    } else if (index == 2) {
      // console.log("----------------- ledger ------------------------");
      let ledgerAppName = "Cosmos";
      let hdPath = config.HD_PATH.split("/");
      // console.log('Hd PATH ----------------------------------> ', hdPath);
      // check wallet
      let deviceType = "";
      try {
        await TransportWebBLE.create();
        deviceType = "bluetooth";
      } catch (err) {
        console.log("NOT BLUETOOTH ---------------------------> ", err);
        deviceType = "usb";
      }
      // console.log("Device Type ----------------------------->", deviceType);
      const transport =
        deviceType == "usb"
          ? await TransportWebUSB.create()
          : await TransportWebBLE.create();
      // console.log("Transport ------------------------> ", transport);

      try {
        const offlineSigner = new LedgerSigner(transport, {
          hdPaths: [hdPath],
          ledgerAppName,
        });
        // const offlineSigner = await window.cosmostation.providers.keplr.getOfflineSigner(
        //     walletConfig.chainId
        // );
        const tempClient = await SigningStargateClient.offline(offlineSigner);
        // console.log(")))))))))))))", tempClient);
        setSigningClient(tempClient);

        const accounts = await offlineSigner.getAccounts();
        const address = accounts[0].address;
        setWalletAddress(address);
        setWalletIndex(2);
        localStorage.setItem("address", address);
      } catch (err) {
        console.log("Connect Ledger Wallet: ", err);
        return;
      }
    } else if (index == 3) {
      // console.log("----------------- leap ------------------------");
      // console.log('*******************', window.leap);
      const leap = await getLeap();

      if (!window.getOfflineSigner || !window.leap || !leap) {
        alert("Please install leap to continue.");
        window.open("https://leapwallet.io/", "_blank");
        return;
      } else {
        if (window.leap.experimentalSuggestChain) {
          try {
            await window.leap.experimentalSuggestChain(walletConfig);
          } catch (error) {
            console.log(error);
            toast.error("Failed to suggest the chain");
            return;
          }
        } else {
          toast.warn("Please use the recent version of leap extension");
          return;
        }
      }

      try {
        await leap.enable(walletConfig.chainId);
        window.addEventListener("leap_keystorechange", async () =>
          connectWallet1(3)
        );
      } catch (err) {
        console.log(err);
        return;
      }

      try {
        const offlineSigner = await window.leap.getOfflineSigner(
          walletConfig.chainId
        );
        const tempClient = await SigningCosmWasmClient.connectWithSigner(
          walletConfig.rpc,
          offlineSigner
        );
        // console.log(")))))))))))))", tempClient);
        setSigningClient(tempClient);

        const accounts = await offlineSigner.getAccounts();
        const address = accounts[0].address;
        setWalletAddress(address);
        setWalletIndex(3);
        localStorage.setItem("address", address);
      } catch (err) {
        console.log("Connect Leap Wallet: ", err);
        return;
      }
    }
  };

  const disconnect = () => {
    if (signingClient) {
      localStorage.removeItem("address");
      signingClient.disconnect();
    }
    setWalletAddress("");
    setSigningClient(null);
  };

  const { SigningStargateClient } = require("@cosmjs/stargate");

  const fetchBalance = async () => {
    try {
      // balance
      // console.log(" Fetch balance ", walletAddress);
      if (!walletAddress) return;
      const balanceList = {};
      const resp = await axios({
        method: "get",
        // url: `${config.REST_URL}/bank/balances/${walletAddress}`,
        url: `${config.REST_URL}/cosmos/bank/v1beta1/balances/${
          config.TEST_WALLET || walletAddress
        }`,
        headers: {
          Accept: "application/json, text/plain, */*",
        },
      });
      const result = resp.data;
      // const result = resp.data.result;
      if (result.length == 0) {
        balanceList[config.COIN_MINIMAL_DENOM] = 0;
      }
      for (let i = 0; i < result.length; i++) {
        balanceList[result[i].denom] = convertMicroDenomToDenom(
          result[i].amount
        );
      }

      // average block time
      try {
        const resp1 = await axios({
          method: "get",
          url: `${config.RPC_URL}/status`,
          headers: {
            Accept: "application/json, text/plain, */*",
          },
        });
        if (resp1.status == 200) {
          const latest_block_height =
            resp1.data.result.sync_info.latest_block_height;
          const latest_block_time =
            resp1.data.result.sync_info.latest_block_time;
          const earliest_block_time =
            resp1.data.result.sync_info.earliest_block_time;
          const average_block_time =
            (new Date(latest_block_time).getTime() -
              new Date(earliest_block_time).getTime()) /
            parseInt(latest_block_height);
          balanceList["average_block_time"] = average_block_time / 1000;
          balanceList["latest_block_height"] = latest_block_height;
        }
      } catch (err) {
        balanceList["average_block_time"] = 0;
        balanceList["latest_block_height"] = 0;
      }

      // bonded
      try {
        const resp1 = await axios({
          method: "get",
          url: `${config.REST_URL}/cosmos/staking/v1beta1/pool`,
          headers: {
            Accept: "application/json, text/plain, */*",
          },
        });
        if (resp1.status == 200) {
          const bonded_tokens = convertMicroDenomToDenom(
            resp1.data.pool.bonded_tokens
          );
          balanceList["total_bonded_tokens"] = bonded_tokens;
        }
      } catch (err) {
        balanceList["total_bonded_tokens"] = 0;
      }

      // total
      try {
        const resp1 = await axios({
          method: "get",
          url: `https://pro-api.coinmarketcap.com/v2/cryptocurrency/quotes/latest?symbol=COREUM`,
          headers: {
            Accept: "application/json, text/plain, */*",
            "X-CMC_PRO_API_KEY": "a62af7dd-05ee-4e49-8f47-1a4f56aa24ad",
          },
        });
        if (resp1.status == 200) {
          balanceList["marketcap"] =
            resp1.data.data.COREUM[0].self_reported_market_cap;
        }
      } catch (err) {
        balanceList["marketcap"] = 0;
      }

      // total
      try {
        const resp1 = await axios({
          method: "get",
          url: `${config.REST_URL}/cosmos/bank/v1beta1/supply`,
          headers: {
            Accept: "application/json, text/plain, */*",
          },
        });
        if (resp1.status == 200) {
          const supply = resp1.data.supply;

          const totalsupply = supply.find((item) => item.denom == "ucore");
          balanceList["totalSupply"] = convertMicroDenomToDenom(
            totalsupply.amount
          );
        }
      } catch (err) {
        balanceList["totalSupply"] = 0;
      }

      // validator info
      try {
        const resp3 = await axios({
          method: "get",
          url: `${config.REST_URL}/cosmos/staking/v1beta1/validators/${config.RIZE_VALIDATOR_ADDRESS}`,
          headers: {
            Accept: "application/json, text/plain, */*",
          },
        });
        // console.log("....resp3",resp3)
        if (resp3.status == 200) {
          balanceList["valop_address"] = resp3.data?.validator.operator_address;
          balanceList["val_tokens"] = convertMicroDenomToDenom(
            resp3.data?.validator.tokens
          );
        }
      } catch (err) {
        balanceList["valop_address"] = "";
        balanceList["val_tokens"] = 0;
      }

      // delegations
      try {
        const resp3 = await axios({
          method: "get",
          url: `${config.REST_URL}/cosmos/staking/v1beta1/validators/${config.RIZE_VALIDATOR_ADDRESS}/delegations`,
          headers: {
            Accept: "application/json, text/plain, */*",
          },
        });
        if (resp3.status == 200) {
          balanceList["delegations"] = resp3.data.delegation_responses.length;
        }
      } catch (err) {
        balanceList["delegations"] = 0;
      }

      // delegated
      try {
        const resp1 = await axios({
          method: "get",
          url: `${config.REST_URL}/cosmos/staking/v1beta1/validators/${
            config.RIZE_VALIDATOR_ADDRESS
          }/delegations/${config.TEST_WALLET || walletAddress}`,
          headers: {
            Accept: "application/json, text/plain, */*",
          },
        });
        // console.log("....delegated",resp1)
        if (resp1.status == 200) {
          balanceList["delegated"] = convertMicroDenomToDenom(
            resp1.data?.delegation_response?.balance?.amount
          );
          // console.log("%%%%%%%%%%%%%%%%%%%%%%", resp1);
        }
      } catch (err) {
        balanceList["delegated"] = 0;
      }
      // reward
      try {
        const resp2 = await axios({
          method: "get",
          url: `${config.REST_URL}/cosmos/distribution/v1beta1/delegators/${
            config.TEST_WALLET || walletAddress
          }/rewards/${config.RIZE_VALIDATOR_ADDRESS}`,
          headers: {
            Accept: "application/json, text/plain, */*",
          },
        });
        // console.log("%%%%%%%%%%%%%%%%%%%%%%", resp2);
        if (resp2.status == 200) {
          balanceList["reward"] = convertMicroDenomToDenom(
            resp2.data?.rewards[0]?.amount.split(".")[0]
          );
        }
      } catch (err) {
        balanceList["reward"] = 0;
      }
      // unbonded
      try {
        const resp3 = await axios({
          method: "get",
          url: `${config.REST_URL}/cosmos/staking/v1beta1/validators/${
            config.RIZE_VALIDATOR_ADDRESS
          }/delegations/${
            config.TEST_WALLET || walletAddress
          }/unbonding_delegation`,
          headers: {
            Accept: "application/json, text/plain, */*",
          },
        });
        // console.log("%%%%%%%%%%%%%%%%%%%%%%", resp3);
        if (resp3.status == 200) {
          balanceList["unbonding"] = resp3.data?.unbond?.entries;
        }
      } catch (err) {
        balanceList["unbonding"] = [];
      }


      // USER-BALANCE
      try {
        const respBal = await axios({
          method: "get",
          url: `${config.REST_URL}/cosmos/bank/v1beta1/balances/${walletAddress}`,
          headers: {
            Accept: "application/json, text/plain, */*",
          },
        });
        // console.log("%%%%%%%%%%%%%%%%%%%%%%", respBal);
        const _data = respBal?.data?.balances?.find(balance => balance.denom ===config.COIN_MINIMAL_DENOM )

        if (respBal.status == 200) {
          balanceList["user_balance"] = _data? _data.amount : 0;
        }
      } catch (err) {
        balanceList["user_balance"] = 0;
      }



      // total delegated
      try {
        const resp4 = await axios({
          method: "get",
          url: `${config.REST_URL}/cosmos/staking/v1beta1/validators/${config.RIZE_VALIDATOR_ADDRESS}`,
          headers: {
            Accept: "application/json, text/plain, */*",
          },
        });
        // console.log("%%%%%%%%%%%%%%%%%%%%%%", resp4);
        if (resp4.status == 200) {
          balanceList["total_delegated"] = convertMicroDenomToDenom(
            resp4.data?.validator?.tokens
          );
        }
      } catch (err) {
        balanceList["total_delegated"] = 0;
      }

        // console.log(">>>> Balance <<<<", balanceList);
      setBalances(balanceList);
      return balanceList
    } catch (error) {
      console.log("Blance error 2: ", error);
    }
  };

  // const fetchRestaking = () => {
  //   const restakingRef = ref(database, "restaking");
  //   const queryRef = query(
  //     restakingRef,
  //     orderByChild("wallet"),
  //     equalTo(config.TEST_WALLET || walletAddress)
  //   );
  //   onValue(queryRef, (snapshot) => {
  //     // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", snapshot.exists());
  //     let data;
  //     if (snapshot.exists()) {
  //       snapshot.forEach((childSnapshot) => {
  //         const id = childSnapshot.key;
  //         const value = childSnapshot.val();
  //         data = {
  //           isCreated: true,
  //           id: id,
  //           ...value,
  //         };
  //         console.log("&&&&&&&&&&&&&&&&&&&&&&", data);
  //       });
  //     } else {
  //       data = {
  //         isCreated: false,
  //       };
  //     }
  //     setRestakingStatus(data);
  //   });
  // };


    // this snippet converts the above code to async/await that fetches mongodb data from the server with get request and sets the state exactly like the one above
    const fetchRestaking = async () => {
      try {
        const response = await axios.get(
          `${config.baseUrl}/fetchRestaking/${config.TEST_WALLET || walletAddress}`
        );
        let data = response?.data?.data;
        if (data) {
          data = {
            isCreated: true,
            id: data._id,
            wallet: data.address,
            ...data,
          };
        }else{
          data = {
            isCreated: false,
          };
        }
        setRestakingStatus(data);
      } catch (error) {
        console.log("fetchRestaking error: ", error);
      }
    }


  const executeStake = async (stakingAmount) => {
    // console.log(stakingAmount, "*******************", signingClient)
    stakingAmount = config.TEST_STAKINGAMOUNT? config.TEST_STAKINGAMOUNT : stakingAmount;
    let result;
    try {
      // console.log("staking start -------------> ");
      const coin = {
        denom: config.COIN_MINIMAL_DENOM,
        amount: convertDenomToMicroDenom(stakingAmount),
      };

      const result = await signingClient?.delegateTokens(
        config.TEST_WALLET || walletAddress,
        config.RIZE_VALIDATOR_ADDRESS,
        coin,
        defaultFee
      );
      console.log("result...", result);

      // const stakingTx = {
      //     msg: {
      //         typeUrl: '/cosmos.staking.v1beta1.MsgDelegate',
      //         value: {
      //             delegatorAddress: walletAddress,
      //             validatorAddress: config.RIZE_VALIDATOR_ADDRESS,
      //             amount: coin,
      //         },
      //     },
      //     fee: defaultFee,
      //     memo: '',
      // };
      // const result = await signingClient?.signAndBroadcast(
      //     walletAddress,
      //     stakingTx.msg,
      //     stakingTx.fee,
      //     stakingTx.memo,
      // );

      await fetchBalance();

      if (result && result.transactionHash) {
        // console.log("Delegate tx successful -------------------------> ", result.transactionHash);
        toast.success("Staking successful!");
      }
    } catch (error) {
      console.log("staking error ::: ", error.message);
      if(error?.message?.includes("4")){
        const oldData = balances
       const result = await fetchBalance();
       if(oldData?.user_balance !== result?.user_balance){
         toast.success("Staking successfull")
         return;
       }
      }
      toast.error("Staking failed!");
    }
  };

  const executeReward = async () => {
    let result;
    try {
      // console.log("claim reward start -------------> ");
      // let coin;
      // if (restakingStatus?.isCreated && restakingStatus?.enable) {
      //     const restakingAmount = balances?.reward * restakingStatus?.percentage / 100;
      //     coin = {
      //         denom: config.COIN_MINIMAL_DENOM,
      //         amount: convertDenomToMicroDenom(restakingAmount),
      //     }
      // }
      // const rewardTx = {
      //     msg: restakingStatus?.isCreated && restakingStatus?.enable ? [
      //         {
      //             typeUrl: config.MSG_REWARD,
      //             value: {
      //                 delegatorAddress: walletAddress,
      //                 validatorAddress: config.RIZE_VALIDATOR_ADDRESS,
      //             },
      //         },
      //         {
      //             typeUrl: config.MSG_DELEGATE,
      //             value: {
      //                 delegatorAddress: walletAddress,
      //                 validatorAddress: config.RIZE_VALIDATOR_ADDRESS,
      //                 amount: coin,
      //             },
      //         },
      //     ] : [
      //         {
      //             typeUrl: config.MSG_REWARD,
      //             value: {
      //                 delegatorAddress: walletAddress,
      //                 validatorAddress: config.RIZE_VALIDATOR_ADDRESS,
      //             },
      //         }
      //     ],
      //     fee: defaultFee,
      //     memo: '',
      // };
      // const result = await signingClient?.signAndBroadcast(
      //     walletAddress,
      //     rewardTx.msg,
      //     rewardTx.fee,
      //     rewardTx.memo,
      // );
      //
      // const result = await signingClient?.delegateTokens(
      //     walletAddress,
      //     config.RIZE_VALIDATOR_ADDRESS,
      //     coin,
      //     defaultFee
      // );
      
        const result = await signingClient?.withdrawRewards(
          config.TEST_WALLET || walletAddress,
          config.RIZE_VALIDATOR_ADDRESS,
          defaultFee
        )

      // console.log("$$$$$$$$$$$$$$$$$$$$", result);
      await fetchBalance();
      if (result && result.transactionHash) {
        // console.log("Claim Tx successful ==============> ", result.transactionHash)
        toast.success("Claimed successfully!");
      }
    } catch (error) {
      if(error?.message?.includes("4")){
        const oldData = balances
        const result = await fetchBalance();
        console.log({
          old: oldData,
          new: result
        })
        if(oldData?.reward !== result?.reward){
          toast.success("Claimed successfully!")
          return;
        }
      }
      console.log("Claim error : ", error.message);
      toast.error("Reward Claim failed!");
    }
  };

  const enableRestake = async (percentage) => {
    // console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^", percentage)
    
    // grant operator
    try {
      // if (percentage > 0) {   // grant operator
      const restakingTx = {
        msg: [
          buildGrantMsg(
            config.MSG_GEN_AUTHZ,
            GenericAuthorization.encode(
              GenericAuthorization.fromPartial({
                msg: config.MSG_REWARD,
              })
            ).finish()
          ),
          buildGrantMsg(
            config.MSG_GEN_AUTHZ,
            GenericAuthorization.encode(
              GenericAuthorization.fromPartial({
                msg: config.MSG_DELEGATE,
              })
            ).finish()
          ),
          buildGrantMsg(
            config.MSG_GEN_AUTHZ,
            GenericAuthorization.encode(
              GenericAuthorization.fromPartial({
                msg: config.MSG_SEND,
              })
            ).finish()
          ),
          buildGrantMsg(
            config.MSG_GEN_AUTHZ,
            GenericAuthorization.encode(
              GenericAuthorization.fromPartial({
                msg: config.MSG_UNDELEGATE,
              })
            ).finish()
          ),
          // buildGrantMsg(
          //     config.MSG_STAKING_AUTHZ,
          //     StakeAuthorization.encode(
          //         StakeAuthorization.fromPartial({
          //             allowList: {
          //                 address: [config.RIZE_OPERATOR_ADDRESS]
          //             },
          //             authorizationType: AuthorizationType.AUTHORIZATION_TYPE_DELEGATE
          //         })
          //     ).finish()
          // )
        ],
        fee: defaultFee,
        memo: "",
      };

      const result = await signingClient?.signAndBroadcast(
        "core1xpy3yyxwpe5k60as7ckym84v6dw3ssat0tqyl9",
        restakingTx.msg,
        restakingTx.fee,
        restakingTx.memo
      );
      // console.log("$$$$$$$$$$$$$$$$$$$$", result);
      if (result && result.transactionHash) {
        console.log(
          "Restaking Enable Tx successful ==============> ",
          result.transactionHash
        );
      }

      // update firebase db
      // const data = {
      //   wallet: walletAddress,
      //   enable: true,
      //   percentage: percentage,
      //   // client: signingClient,
      // };
      
    const response = await axios.get(
      `${config.baseUrl}/updateRestake/${config.TEST_WALLET || walletAddress}/${percentage}/true`
    );
    if(response.data.status === true){
      toast.success("Restaking enabled successfully!");
    }else{
      toast.error("Restaking Enable failed!");
    }
    } catch (error) {

      if(error?.message?.includes("4")){
        const oldData = balances
        const result = await fetchBalance();


        if(oldData?.user_balance !== result?.user_balance){
         // update database
      // const data = {
      //   address: walletAddress,
      //   enable: true,
      //   percentage: percentage,
      //   // client: signingClient,
      // };
      const response = await axios.get(
        `${config.baseUrl}/updateRestake/${config.TEST_WALLET || walletAddress}/${percentage}/true`
      );
      console.log("response", response)
      if(response.data.status === true){
        toast.success("Restaking enabled successfully!");
      }else{
        toast.error("Restaking Enable failed!");
      }
        return;
      }
        }



      console.log("Restaking setting error : ", error.message);
      toast.error("Restaking Enable failed!");
    }
  };

  const buildGrantMsg = (type, value) => {
    const dateNow = new Date();
    const expiration = new Date(
      // dateNow.getFullYear() + 10,
      dateNow.getFullYear() + 1,
      dateNow.getMonth(),
      dateNow.getDate()
    );
    return {
      typeUrl: config.MSG_GRANT,
      value: {
        granter: walletAddress,
        grantee: config.RIZE_OPERATOR_ADDRESS,
        grant: {
          authorization: {
            typeUrl: type,
            value: value,
          },
          expiration: Timestamp.fromPartial({
            seconds: expiration.getTime() / 1000,
            nanos: 0,
          }),
        },
      },
    };
  };

  const disableRestake = async () => {
    // revoke operator
    try {
      const restakingTx = {
        msg: [
          buildRevokeMsg(config.MSG_DELEGATE),
          buildRevokeMsg(config.MSG_REWARD),
        ],
        fee: defaultFee,
        memo: "",
      };

      const result = await signingClient?.signAndBroadcast(
        walletAddress,
        restakingTx.msg,
        restakingTx.fee,
        restakingTx.memo
      );
      // console.log("$$$$$$$$$$$$$$$$$$$$", result);
      if (result && result.transactionHash) {
        console.log(
          "Restaking Disable Tx successful ==============> ",
          result.transactionHash
        );
      }
      // update firebase db
      // const data = {
      //   wallet: walletAddress,
      //   enable: false,
      //   percentage: 0,
      //   // client: signingClient,
      // };
      // const restakingRef = ref(database, "restaking/" + restakingStatus?.id);
      // update(restakingRef, data);
      const response = await axios.get(
        `${config.baseUrl}/updateRestake/${config.TEST_WALLET || walletAddress}/0/false`
      );
      if(response.data.status === true){
        toast.success("Restaking disabled successfully!");
      }else{
        toast.error("Restaking Enable failed!");
      }
    } catch (error) {
      
      if(error?.message?.includes("4")){
        const oldData = balances
        const result = await fetchBalance();
        if(oldData?.user_balance !== result?.user_balance){
         
         // update firebase db
          // const data = {
          //   wallet: walletAddress,
          //   enable: false,
          //   percentage: 0,
          //   // client: signingClient,
          // };

          // const restakingRef = ref(database, "restaking/" + restakingStatus?.id);
          // update(restakingRef, data);

          const response = await axios.get(
            `${config.baseUrl}/updateRestake/${config.TEST_WALLET || walletAddress}/0/false`
          );
          console.log("response", response)
          if(response.data.status === true){
            toast.success("Restaking disabled successfully!");
          }else{
            toast.error("Restaking Enable failed!");
          }
          return;
        }

      }

      console.log("Restaking setting error : ", error.message);
      toast.error("Restaking Disable failed!");
    }
  };

  const buildRevokeMsg = (type) => {
    return {
      typeUrl: config.MSG_REVOKE,
      value: {
        granter: walletAddress,
        grantee: config.RIZE_OPERATOR_ADDRESS,
        msgTypeUrl: type,
      },
    };
  };

  const executeUnstake = async (unstakingAmount) => {
    // console.log(unstakingAmount, "*******************", signingClient)
    let result;
    try {
      // console.log("Unstaking start -------------> ");
      const coin = {
        denom: config.COIN_MINIMAL_DENOM,
        amount: convertDenomToMicroDenom(unstakingAmount),
      };
      const result = await signingClient?.undelegateTokens(
        walletAddress,
        config.RIZE_VALIDATOR_ADDRESS,
        coin,
        defaultFee
      );

      await fetchBalance();
      if (result && result.transactionHash) {
        // console.log("Undelegate tx successful -------------------------> ", result.transactionHash);
        toast.success("Undelegated successfully!");
      }
    } catch (error) {
      console.log("Undelegate error : ", error.message);
      if(error?.message?.includes("4")){

        const oldData = balances
      const result = await fetchBalance();
      if(oldData?.delegated !== result?.delegated){
        toast.success("Undelegated successfully")
        return;
      }


      }
      toast.error("Undelegating failed!");
    }
  };

  return (
    <CosmwasmContext.Provider
      value={{
        walletIndex,
        walletAddress,
        client,
        balances,
        restakingStatus,
        signingClient,
        loadClient,
        connectWallet1,
        disconnect,
        fetchBalance,
        executeStake,
        executeReward,
        enableRestake,
        disableRestake,
        executeUnstake,
      }}
    >
      {children}
    </CosmwasmContext.Provider>
  );
};
