import axios from "axios"
import { config } from "./config";

export function convertMicroDenomToDenom(amount, denom = 6) {

    if (typeof amount === 'string') {
        amount = Number(amount)
    }
    amount = amount / 10 ** 6
    return isNaN(amount) ? 0 : amount
}

export function convertDenomToMicroDenom(amount, denom = 6) {
    if (typeof amount === 'string') {
        amount = Number(amount)
    }
    amount = amount * 10 ** 6
    return isNaN(amount) ? '0' : String(amount).split('.')[0];
}

export async function getSystemTime() {
    try {
        const resp = await axios.get(`${config.API_URL}api/users/system_time`);
        return resp.data;
    } catch (err) {
        console.log(err);
    }
    return 0;
}

export const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const copyToClipboard = (data) => {
    if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method'
        return navigator.clipboard.writeText(data);
    } else {
        var textField = document.createElement('textarea')
        textField.innerText = data;
        textField.style.position = "fixed";
        textField.style.left = "-999999px";
        textField.style.top = "-999999px";
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
    }
}

export const secondsToDHM = (sec) => {
    const days = Math.floor(sec / (3600 * 24));
    const hours = Math.floor((sec % (3600 * 24)) / 3600);
    const minutes = Math.floor((sec % 3600) / 60);
    return days + 'd ' + hours + 'h ' + minutes + 'm';
}

/* eslint-disable indent */
/* eslint-disable no-confusing-arrow */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
export function slideUp(element, duration = 500) {
    return new Promise((resolve, _reject) => {
        element.style.height = `${element.offsetHeight}px`;
        element.style.transitionProperty = `height, margin, padding`;
        element.style.transitionDuration = `${duration}ms`;
        element.offsetHeight;
        element.style.overflow = "hidden";
        element.style.height = 0;
        element.style.paddingTop = 0;
        element.style.paddingBottom = 0;
        element.style.marginTop = 0;
        element.style.marginBottom = 0;
        window.setTimeout(() => {
            element.style.display = "none";
            element.style.removeProperty("height");
            element.style.removeProperty("padding-top");
            element.style.removeProperty("padding-bottom");
            element.style.removeProperty("margin-top");
            element.style.removeProperty("margin-bottom");
            element.style.removeProperty("overflow");
            element.style.removeProperty("transition-duration");
            element.style.removeProperty("transition-property");
            resolve(false);
        }, duration);
    });
}

export function slideDown(element, duration = 500) {
    return new Promise((_resolve, _reject) => {
        element.style.removeProperty("display");
        let { display } = window.getComputedStyle(element);

        if (display === "none") display = "block";

        element.style.display = display;
        const height = element.offsetHeight;
        element.style.overflow = "hidden";
        element.style.height = 0;
        element.style.paddingTop = 0;
        element.style.paddingBottom = 0;
        element.style.marginTop = 0;
        element.style.marginBottom = 0;
        element.offsetHeight;
        element.style.transitionProperty = `height, margin, padding`;
        element.style.transitionDuration = `${duration}ms`;
        element.style.height = `${height}px`;
        element.style.removeProperty("padding-top");
        element.style.removeProperty("padding-bottom");
        element.style.removeProperty("margin-top");
        element.style.removeProperty("margin-bottom");
        window.setTimeout(() => {
            element.style.removeProperty("height");
            element.style.removeProperty("overflow");
            element.style.removeProperty("transition-duration");
            element.style.removeProperty("transition-property");
        }, duration);
    });
}

export function slideToggle(element, duration = 500) {
    if (window.getComputedStyle(element).display === "none") {
        return slideDown(element, duration);
    }
    return slideUp(element, duration);
}

export const flatDeep = (arr, d = 1) =>
    d > 0
        ? arr.reduce(
            (acc, val) =>
                acc.concat(Array.isArray(val) ? flatDeep(val, d - 1) : val),
            []
        )
        : arr.slice();

export function slugify(text) {
    return text
        .toString()
        .toLowerCase()
        .replace(/\s+/g, "-") // Replace spaces with -
        .replace(/[^\w-]+/g, "") // Remove all non-word chars
        .replace(/--+/g, "-") // Replace multiple - with single -
        .replace(/^-+/, "") // Trim - from start of text
        .replace(/-+$/, ""); // Trim - from end of text
}

export function normalizedData(data, key = "section") {
    let allContetnt;

    data.forEach((item) => {
        const newObj = Object.entries(item).reduce((acc, cur) => {
            const [k, property] = cur;
            if (property === null) {
                return acc;
            }
            return {
                ...acc,
                [k]: property,
            };
        }, {});

        allContetnt = {
            ...allContetnt,
            [newObj[key]]: {
                ...newObj,
            },
        };
    });

    return allContetnt;
}

export const months = [
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec",
];

export const getMonth = (date) => months[date.getMonth()];

export const containsObject = (obj, list) => {
    let i;
    for (i = 0; i < list.length; i++) {
        if (list[i].slug === obj.slug) {
            return i;
        }
    }

    return -1;
};

export const shuffleArray = (array) => {
    const newArr = array.slice();
    for (let i = newArr.length - 1; i > 0; i--) {
        const rand = Math.floor(Math.random() * (i + 1));
        [newArr[i], newArr[rand]] = [newArr[rand], newArr[i]];
    }
    return newArr;
};

export const hasKey = (obj, key) => !!Object.prototype.hasOwnProperty.call(obj, key);

export const isEmpty = (value) => {
    return (
        value === undefined ||
        value === null ||
        (typeof value === "object" && Object.keys(value).length === 0) ||
        (typeof value === "string" && value.trim().length === 0)
    )
}

export const getShortAddress = (address) => {
    if (isEmpty(address)) return "";
    return (
        address.slice(0, 6) +
        "..." +
        address.slice(address.length - 5, address.length)
    );
};

export const getMidAddress = (address) => {
    if (isEmpty(address)) return "";
    return (
        address.slice(0, 10) +
        "..." +
        address.slice(address.length - 10, address.length)
    );
};

export const getLongAddress = (address) => {
    if (isEmpty(address)) return "";
    return (
        address.slice(0, 25) +
        "..." +
        address.slice(address.length - 25, address.length)
    );
};

export const numberWithCommas = (x, digit = 3) => {
    if (isEmpty(x) || isNaN(x)) return "0";
    return Number(x).toLocaleString(undefined, {
        maximumFractionDigits: digit,
    });
};

export const getExtension = (filename) => {
    return filename.split(".").pop();
};

export const setExpiry = (years) => {
    const d = new Date()
    const y = d.getFullYear()
    const months = d.getMonth()
    const days = d.getDay()
    return new Date(y + years, months, days)
}

export const formatNumberWithCommas = (number) => {
    if (number == undefined || number == "")
        return ""
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// helper function for correcting test address on cosmostation
export const correctTestAddress = (address) => {
    if (address.startsWith("utest")) {
        return address.slice(1);
    }
    return address;
};